import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { merge as observableMerge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { BaseProcessPage } from '@shared/components/templates/process-page/process-page.component';
import { UCError } from '@shared/models/errors';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { LeadService } from '@shared/services/lead/lead.service';
import { LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-agent-pre-process-page',
  templateUrl: './agent-pre-process-page.component.html',
  styleUrls: ['./agent-pre-process-page.component.scss'],
})
export class AgentPreProcessPageComponent extends BaseProcessPage implements OnInit, AfterViewInit {
  public availableApplicationYears: string[];
  public strings = strings.components.template.processPage;
  public errorMessage = '';
  public stageComplete = false;
  public isFirstTask = false;
  public taskIsUpdating = false;
  public actionLabel: string;
  public applicationYear: string;
  public newProcessName: string;
  disableNavigation = false;
  isImpersonating = false;
  firstIncompleteStageNumber = -1;

  task = new Task({
    title: 'Select Application Year',
    code: 'future-year-apply',
    percentComplete: 0,
    path: '/apply/new_student',
    stop: false,
  });

  stage = new Stage({
    title: 'Get started',
    description: 'Get started',
    code: 'get-started',
    percentComplete: 0,
    path: '/apply/new_student',
    tasks: [this.task],
  });

  process = new UCProcess({
    title: 'New Student',
    code: 'new_student',
    stages: [this.stage],
  });

  constructor(
    private router: Router,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private leadService: LeadService,
    private loggingService: LoggingService,
  ) {
    super();
    this.log = this.loggingService.createLogger(this);
  }

  ngAfterViewInit() {
    if (!this.errorMessage) {
      this.hideErrorBanner();
    }
  }

  ngOnInit() {
    this.actionLabel = 'Save and Continue';

    observableMerge(
      this.applicantService.applicantError,
      this.leadService.leadError,
      this.applicationService.applicationError,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((error) => {
        if (error.code.match(/(missing|fourOhFour)/)) {
          return;
        }
        if (error.code.match(/fourTwentyTwo/)) {
          this.errorMessage = error.data[0].original_msg;
          if (this.currentTask.updateFormValidity) {
            this.currentTask.updateFormValidity(error);
          } else {
            this.log.error(
              new Error('Error: task components must extend AbstractBaseTask and implement updateFormValidity'),
            );
          }
        } else {
          this.errorMessage = get(strings, error.code);
        }
        this.showErrorBanner();
        this.taskIsUpdating = false;
      });
  }

  showTaskError(error?: UCError) {
    this.taskIsUpdating = false;
    if (error) {
      this.errorMessage = error.data;
      if (error.code) {
        this.errorMessage = get(strings, error.code) as string;
      }
      this.showErrorBanner();
    }
  }

  previousTask() {
    this.router.navigate(internalUrls.dashboard);
  }

  cancelTask() {
    this.router.navigate(internalUrls.dashboard);
  }

  triggerTaskUpdate() {
    this.taskIsUpdating = true;
    this.hideErrorBanner();
    if (this.currentTask && this.currentTask.update) {
      this.currentTask.update();
    } else {
      this.log.warn('could not update task because no reference to current task exists');
    }
  }

  goToNextTask() {
    // ensures goToNextTask() super method in BaseProcessPage does not get called
  }

  jumpToContent(jumpToElement: string): void {
    return super.jumpToContent(jumpToElement);
  }
}
