import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash-es';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';

import { environment } from '@environment';
import { UCError } from '@shared/models/errors';
import { Lead, LeadTableData } from '@shared/models/lead';
import { Page } from '@shared/models/page';
import { DataService } from '@shared/services/data-service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

import { mockData as leadsMockData } from './leads.data.mock';

export interface ILeadQueryOptions {
  page?: number;
  academicYear?: string;
  emailAddress?: string;
  orderBy?: string;
  orderDirection?: string;
}

@Injectable()
export class LeadService {
  private serviceUrl = `${environment.apiRoot}/lead/`;
  private log: Logger;
  private leadTableData$ = new BehaviorSubject<LeadTableData>(null);
  private leads$ = new BehaviorSubject<Lead[]>(null);
  private page$ = new BehaviorSubject<Page>(null);
  private error$ = new Subject<UCError>();

  constructor(loggingService: LoggingService, private dataService: DataService) {
    this.log = loggingService.createLogger(this);
  }

  get leads(): Observable<Lead[]> {
    return this.leads$.asObservable();
  }

  get page(): Observable<Page> {
    return this.page$.asObservable();
  }

  get leadError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  deserializeLeadTableData(leadTableData) {
    const deserializedPage = Page.deserialize(leadTableData.page);
    const deserializedLeads = Lead.deserialize(leadTableData.leads);
    this.leads$.next(deserializedLeads);
    this.page$.next(deserializedPage);
  }

  public buildGetLeadsUrl(queryOptions?) {
    const url = this.serviceUrl;
    let queryParams = '';

    if (queryOptions && Object.keys(queryOptions).length > 0) {
      const validKeys = Object.keys(queryOptions).filter((key) => queryOptions[key] !== '');
      const params = validKeys.map((el) => {
        const key = snakeCase(el);
        return `${key}=${queryOptions[el]}`;
      });
      queryParams = `?${params.join('&')}`;
    }

    return url + queryParams;
  }

  public getLeads(queryOptions?: ILeadQueryOptions): Observable<LeadTableData[]> {
    const leadUrl = this.buildGetLeadsUrl(queryOptions);
    return this.dataService.fetch(leadUrl, {
      success$: this.leadTableData$,
      error$: this.error$,
      deserialize: this.deserializeLeadTableData.bind(this),
    });
  }

  public createLead(lead): Observable<Lead> {
    return this.dataService.post(this.serviceUrl, Lead.serialize(lead), {
      error$: this.error$,
      deserialize: (payload) => Lead.deserialize([payload.lead])[0],
    });
  }

  public removeLead(lead): void {
    const leadsUpdated = this.leads$.value.filter((l) => l.userId !== lead.userId);
    this.leads$.next(leadsUpdated);
  }
}

export class MockLeadService {
  mockLeadTableData: { leads: ReturnType<typeof leadsMockData>; page: Page };
  private leadTableData$ = new BehaviorSubject<LeadTableData>(null);
  private leads$ = new BehaviorSubject<Lead[]>(null);
  private page$ = new BehaviorSubject<Page>(null);
  private error$ = new Subject<UCError>();

  constructor() {
    this.mockLeadTableData = {
      leads: leadsMockData(),
      page: new Page({
        pageSize: 4,
        totalItems: 4,
        totalPages: 1,
        /* eslint-disable-next-line id-blacklist */
        number: 1,
      }),
    };
  }

  get leads(): Observable<Lead[]> {
    return this.leads$.asObservable();
  }

  get page(): Observable<Page> {
    return this.page$.asObservable();
  }

  get leadError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  deserializeLeadTableData(leadTableData) {
    const deserializedPage = Page.deserialize(leadTableData.page);
    const deserializedLeads = Lead.deserialize(leadTableData.leads);
    this.leads$.next(deserializedLeads);
    this.page$.next(deserializedPage);
  }

  public getLeads(queryOptions?: ILeadQueryOptions): Observable<Lead[]> {
    let leads = Lead.deserialize(leadsMockData().leads);
    const page = Page.deserialize(this.mockLeadTableData.page);
    if (queryOptions) {
      if (queryOptions.emailAddress) {
        leads = leads.filter((lead) => lead.emailAddress === queryOptions.emailAddress);
      }
      if (queryOptions.academicYear) {
        leads = leads.filter((lead) => lead.academicYear.code === queryOptions.academicYear);
      }
      page.totalItems = leads.length;

      if (queryOptions.page) {
        if (queryOptions.page === 0) {
          leads = leads.splice(2, 4);
        } else {
          leads = leads.splice(0, 2);
        }
      }
    }

    this.leads$.next(leads);
    this.page$.next(page);
    this.leadTableData$.next({ leads, page });
    return this.leads;
  }

  public createLead(lead: Lead): Observable<Lead> {
    const clone = new Lead(lead);
    clone.userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return of(clone);
  }

  public removeLead(lead: Lead): void {
    const leadsUpdated = this.leads$.value.filter((l) => l.userId !== lead.userId);
    this.leads$.next(leadsUpdated);
  }
}

export const leadServiceFactory = (loggingService, dataService): LeadService | MockLeadService => {
  if (environment.useFakeBackend.lead) {
    return new MockLeadService();
  } else {
    return new LeadService(loggingService, dataService);
  }
};

export const leadServiceProvider = {
  provide: LeadService,
  useFactory: leadServiceFactory,
  deps: [LoggingService, DataService],
};
