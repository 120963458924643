import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from as fromPromise } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { touchAll } from '@shared/helpers/general';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { Contact } from '@shared/models/contact';
import { Lead } from '@shared/models/lead';
import { Name } from '@shared/models/name';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { LeadService } from '@shared/services/lead/lead.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-agent-future-apply',
  templateUrl: './agent-future-apply.component.html',
  styleUrls: ['./agent-future-apply.component.scss'],
})
export class AgentFutureApplyComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;

  strings = strings.components.tasks.agentFutureApply;
  agentReminderString = strings.components.template.processPage.agentReminder;
  form: UntypedFormGroup;
  dateOfBirthYear = new Date().getFullYear() - 10;
  log: Logger;
  markFullNameDirty = false;

  processNameOptions = [
    {
      labelText: this.strings.processLabels.newStudent,
      value: PROCESS_NAMES.NEW_STUDENT,
    },
    {
      labelText: this.strings.processLabels.studyAbroad,
      value: PROCESS_NAMES.STUDY_ABROAD,
    },
  ];

  processNameAdditionalText = [
    {
      code: PROCESS_NAMES.NEW_STUDENT,
      inlineGuidance: this.strings.processGuidance.newStudent,
    },
    {
      code: PROCESS_NAMES.STUDY_ABROAD,
      inlineGuidance: this.strings.processGuidance.studyAbroad,
    },
  ];

  constructor(
    private loggingService: LoggingService,
    private fb: UntypedFormBuilder,
    private leadService: LeadService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private userService: UserService,
    private router: Router,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  updateFormValidity() {
    // No-op
  }

  ngOnInit() {
    this.form = this.fb.group({
      legalName: [new Name({}), Validators.required],
      applicationYear: ['', Validators.required],
      emailAddress: ['', Validators.compose([Validators.email, Validators.required])],
      birthDate: ['', UCValidators.validateDate],
      processName: ['', Validators.required],
    });
  }

  update() {
    if (!this.form.valid) {
      let data;
      touchAll(this.form);
      this.markFullNameDirty = true;
      if (this.form.get('emailAddress').hasError('email')) {
        data = this.strings.invalidEmail;
      }
      Object.keys(this.form.value).map((key) => {
        if (!this.form.value[key]) {
          this.form.get(key).setErrors({ invalid: true });
          data = this.strings.formIncomplete;
        }
      });
      return this.errors.emit({ data, code: '' });
    }

    const lead = new Lead({
      academicYear: this.form.get('applicationYear').value,
      firstName: this.form.get('legalName').value.firstName,
      surname: this.form.get('legalName').value.surname,
      emailAddress: this.form.get('emailAddress').value,
      birthDate: this.form.get('birthDate').value,
      processName: new ReferenceData({ code: this.form.get('processName').value }),
    });

    const applicant = new Applicant({
      legalName: this.form.get('legalName').value,
      birthDate: this.form.get('birthDate').value,
      contactDetail: new Contact({ emailAddress: this.form.get('emailAddress').value }),
    });

    this.leadService
      .createLead(lead)
      .pipe(
        switchMap((l) => this.userService.impersonate(l.userId)),
        switchMap(() => this.applicantService.updateApplicant(applicant)),
        switchMap(() =>
          this.applicationService.createApplication(
            new Application({
              academicYear: this.form.get('applicationYear').value,
              processName: { code: this.form.get('processName').value },
            }),
          ),
        ),
        switchMap(() => {
          const applicationYear = this.form.get('applicationYear').value.code;
          const processCode = this.form.get('processName').value;
          return fromPromise(this.router.navigate(internalUrls.processPage(processCode, applicationYear)));
        }),
      )
      .subscribe({
        next: () => {
          // No-op
        },
        error: () => {
          // No-op
        },
      });
  }
}
