import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { get, snakeCase } from 'lodash-es';
import { Observable, of, combineLatest, zip } from 'rxjs';
import { switchMap, map, filter, takeUntil, catchError } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { LEAD_ACTIONS } from '@shared/constants/actions.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { Application } from '@shared/models/application';
import { Lead } from '@shared/models/lead';
import { Page } from '@shared/models/page';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LeadService } from '@shared/services/lead/lead.service';
import { ModalService, IModalOptions } from '@shared/services/modal/modal.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.scss'],
})
export class DashboardTemplateComponent extends AbstractBaseTask implements OnInit {
  strings = strings.components.template.dashboard;
  appStatusStrings = strings.components.atoms.statusPill;
  leads: Lead[];
  page: Page;
  selected = [];
  internalUrls = internalUrls;
  modalOpen = false;
  withdrawing = false;
  loading = true;
  initialLoadingSpinner = true;
  applicationYears: string[] = [];
  leadQueryParams = {};

  defaultSort = [{ prop: 'dateCreated', dir: 'desc' }];

  formGroup = this.formBuilder.group({
    emailAddress: '',
    academicYear: '',
  });

  actions = {
    [LEAD_ACTIONS.RESUME]: {
      handler: (lead) => {
        const navigationRoute = internalUrls.processPage(lead.processName.code, lead.academicYear.code);
        return this.userService.impersonateAndNavigate(lead.userId, navigationRoute);
      },
      html: `<span>${this.strings.actions.resume}</span>`,
    },
    [LEAD_ACTIONS.UPLOAD_DOCS]: {
      handler: (lead) => {
        return this.userService.impersonateAndNavigate(lead.userId, internalUrls.documents);
      },
      html: `<span>${this.strings.actions.uploadDocs}</span>`,
    },
    [LEAD_ACTIONS.DOCUMENTS_AVAILABLE]: {
      handler: (lead) => {
        return this.userService.impersonateAndNavigate(lead.userId, internalUrls.documents);
      },
      html: `<span>${this.strings.actions.docsAvailable}</span>`,
    },
    [LEAD_ACTIONS.ARCHIVE]: {
      handler: (lead) => of(true),
      html: `<span>${this.strings.actions.archive}</span>`,
    },
    [LEAD_ACTIONS.WITHDRAW]: {
      handler: (lead) => this.showWithdrawModal(lead),
      html: `<span>${this.strings.actions.withdraw}</span>`,
    },
    [LEAD_ACTIONS.HAS_APPLIED_CONDITIONS]: {
      handler: (lead) => {
        return this.userService.impersonateAndNavigate(
          lead.userId,
          internalUrls.informationRequired(lead.academicYear.code),
        );
      },
      html: `<span>${this.strings.actions.infoRequired}</span>`,
    },
  };

  columnSortMap = {
    displayBirthDate: 'birth_date',
    displayDateCreated: 'date_created',
    displayDateSubmitted: 'date_submitted',
  };

  hasPrimaryAction(lead: Lead): string {
    return get(lead, 'actions.primary.action');
  }

  constructor(
    private userService: UserService,
    private leadService: LeadService,
    public flashMessageService: FlashMessageService,
    private applicationService: ApplicationService,
    private modalService: ModalService,
    private qualificationService: QualificationService,
    private formBuilder: UntypedFormBuilder,
    private refDataService: ReferenceDataService,
  ) {
    super();
  }

  ngOnInit() {
    zip(this.leadService.leads, this.leadService.page)
      .pipe(
        filter(([leads, page]) => !!leads && !!page),
        takeUntil(this.componentDestroyed),
      )
      .subscribe(([leads, page]) => {
        this.leads = leads;
        this.page = page;

        this.getIntakeLabels();
        if (!this.leads.length) {
          this.loading = false;
          this.initialLoadingSpinner = false;
        }
      });

    this.refDataService.getByType('application_year').subscribe((yearData) => {
      const appYears = yearData.map((el) => el.code);
      const firstAppYear = parseInt(appYears[0], 10);
      const previousYears = [(firstAppYear - 2).toString(), (firstAppYear - 1).toString()];
      this.applicationYears = previousYears.concat(appYears);
    });

    this.leadService.getLeads().subscribe();
  }

  setPage(pageInfo) {
    this.loading = true;
    const newOffset = pageInfo.offset + 1;
    this.page.number = newOffset;
    this.leadQueryParams = { ...this.leadQueryParams, page: newOffset };
    this.leadService.getLeads(this.leadQueryParams).subscribe();
  }

  onSort(event) {
    this.loading = true;
    const sortInfo = event.sorts[0];
    const orderByCamel = sortInfo.prop.split('.')[0];
    const orderBy = this.columnSortMap[orderByCamel] || snakeCase(orderByCamel);
    const orderDirection = sortInfo.dir;
    const newParams = {
      page: 1,
      orderBy,
      orderDirection,
    };
    this.leadQueryParams = { ...this.leadQueryParams, ...newParams };
    this.leadService.getLeads(this.leadQueryParams).subscribe();
  }

  filterLeads() {
    this.loading = true;
    const newParams = {
      academicYear: this.formGroup.get('academicYear').value,
      emailAddress: this.formGroup.get('emailAddress').value,
    };
    this.leadQueryParams = { ...this.leadQueryParams, ...newParams };
    this.leadService.getLeads(this.leadQueryParams).subscribe();
  }

  resetFilters() {
    this.loading = true;
    this.leadQueryParams = {};
    this.formGroup.get('academicYear').reset('');
    this.formGroup.get('emailAddress').reset('');
    this.leadService.getLeads().subscribe();
  }

  updateFormValidity() {}

  getIntakeLabels() {
    // Loops over each qualification on each lead and adds an subscription to obs[] for each
    // if a qual occurrence is set, it builds a separate array of observables to fetch the occurrence intake label
    // this leaves an array of intake labels that we can use to update the leads array
    const obs: Observable<string>[] = [];
    let qualOccSubs: Observable<string>[] = [];

    for (const lead of this.leads) {
      const hasQuals = !!lead.qualifications.length;
      let startLabelPushed = false;

      if (!hasQuals) {
        obs.push(of(lead.studyStartLabel));
      } else {
        // Loop over each qual and get either study start label or correct qual occurrence intake label
        for (const qual of lead.qualifications) {
          if (!qual.qualificationOccurrence) {
            if (!startLabelPushed) {
              // For concurrent quals with no qual occurrences we only want to capture the study start label once
              qualOccSubs.push(of(lead.studyStartLabel));
              startLabelPushed = true;
            }
          } else {
            const qualOccurrenceLabelOb = this.qualificationService
              .getQualificationOccurrence(qual.qualificationOccurrence)
              .pipe(
                map((qo) => {
                  return qo.intakeName;
                }),
                catchError((err: DSHttpError) => {
                  if (err.code === UCErrorCodes.E404) {
                    return of(this.strings.intakeUnavailable);
                  }
                }),
              );
            qualOccSubs.push(qualOccurrenceLabelOb);
          }
        }

        const qualOccSubsZipped = zip(...qualOccSubs).pipe(map((arr) => arr.join(' / ')));

        qualOccSubs = [];
        obs.push(qualOccSubsZipped);
      }
    }

    combineLatest(obs).subscribe((intakeLabels) => {
      intakeLabels.forEach((label, i) => {
        if (this.leads[i]) {
          this.leads[i].intakeLabel = label;
        }
        if (i === this.leads.length - 1) {
          this.loading = false;
          this.initialLoadingSpinner = false;
        }
      });
    });
  }

  getStatusColour(status) {
    return Application.STATE_COLOURS[status];
  }

  getRowHeight(row) {
    return row.height;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  primaryActionHandler(lead: Lead) {
    const primaryAction = this.hasPrimaryAction(lead);
    if (primaryAction) {
      this.invokeActionHandler(primaryAction, lead);
    }
  }

  private invokeActionHandler(action: string, lead: Lead) {
    const actionData = this.actions[action];
    if (actionData) {
      actionData.handler(lead).subscribe();
    }
  }

  viewDocuments(lead: Lead) {
    this.invokeActionHandler(LEAD_ACTIONS.DOCUMENTS_AVAILABLE, lead);
  }

  viewInfoRequired(lead: Lead) {
    this.invokeActionHandler(LEAD_ACTIONS.HAS_APPLIED_CONDITIONS, lead);
  }

  buildActionObjects(lead: Lead) {
    if (get(lead, 'actions.secondary.length')) {
      return lead.actions.secondary.map((secondary) => this.actions[secondary.action]);
    }
    return [];
  }

  withdrawApplication(lead: Lead) {
    if (this.withdrawing) {
      return;
    }
    this.withdrawing = true;

    const cleanup = () => {
      this.userService.unimpersonate();
      this.withdrawing = false;
    };

    const success = () => {
      cleanup();
      this.leadService.getLeads().subscribe();
      this.flashMessageService.pushSuccess(this.strings.withdrawSuccess, { countdown: 20 });
    };

    const error = (err: DSHttpError) => {
      cleanup();
      if (err.code === UCErrorCodes.E422) {
        const modalText = this.strings.withdrawApplicationProblem;
        this.showAlertModal(this.createModalOptions(modalText));
      }
    };

    return this.userService
      .impersonate(lead.userId)
      .pipe(switchMap(() => this.applicationService.deleteApplication(lead.academicYear.code)))
      .subscribe(success, error);
  }

  showWithdrawModal(lead: Lead): Observable<null> {
    this.showModal(
      this.createModalOptions({ handler: this.withdrawApplication.bind(this, lead) }, this.strings.withdrawApplication),
    );
    return of(null);
  }

  modalClosed() {
    this.modalOpen = false;
  }

  createModalOptions(modalText, handler = {}): IModalOptions {
    const baseOptions = { closeHandler: this.modalClosed.bind(this) };
    return Object.assign(baseOptions, handler, modalText);
  }

  showModal(config: IModalOptions): void {
    if (this.modalOpen) {
      return;
    }
    this.modalOpen = true;
    this.modalService.showModal(config);
  }

  showAlertModal(modalStrings: IModalOptions) {
    if (this.modalOpen) {
      return;
    }
    this.modalOpen = true;
    this.modalService.showAlertModal(modalStrings);
  }
}
