import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HeaderBarComponent } from '@shared/components/organisms/header-bar/header-bar.component';
import { DocumentService } from '@shared/services/document/document.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-agent-header-bar',
  templateUrl: './agent-header-bar.component.html',
  styleUrls: ['./agent-header-bar.component.scss'],
})
export class AgentHeaderBarComponent extends HeaderBarComponent {
  @Input() hasSideNav: boolean;
  @Output() openSidenav = new EventEmitter();

  /* eslint-disable-next-line @typescript-eslint/no-useless-constructor */
  constructor(
    router: Router,
    documentService: DocumentService,
    modalService: ModalService,
    userService: UserService,
    flashMessageService: FlashMessageService,
    route: ActivatedRoute,
    loggingService: LoggingService,
  ) {
    super(router, documentService, modalService, userService, flashMessageService, route, loggingService);
  }

  hamburgerClick() {
    this.openSidenav.emit();
  }
}
