// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// Other student web link http://ucapp1u.canterbury.ac.nz/Sandpit/JadeSMS/Login.aspx
import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

import { UCEnvironment } from '@shared/environment-definition';
import { UserTypes } from '@shared/models/user';

export const environment: UCEnvironment = {
  envName: 'default',
  academicYearStart: '01/03',
  scope: UserTypes.agent,
  production: false,
  apiRoot: '/api',
  sharedModelSerializerMode: 'PUT',
  logLevel: 'info',
  envColour: 'pink',
  // use the dsn sentry url here from sentry.catalyst.net.nz
  sentryUrl: null,
  loginMessage: {
    connectUC: `
Welcome to connectUC - this portal replaces the UCLink portal that you have used until now. To
log in to connectUC use the new username and password that was sent by email to your organisation. Please
contact your head office if you have not received the new login details. If you have the new login
details but still require assistance, please contact us at <a href="mailto:admission@canterbury.ac.nz">admission@canterbury.ac.nz</a>`,
  },
  firebase: {
    apiKey: 'AIzaSyCqAHeK_uPC2W8AW3wGT5ovZp0jicSVUEI',
    projectId: 'myuctest-52406',
    authDomain: 'myuctest-52406.firebaseapp.com',
    databaseURL: 'https://myuctest-52406.firebaseio.com',
    storageBucket: 'myuctest-52406.appspot.com',
    messagingSenderId: '203334776419',
  },
  azureB2C: {
    msalConfig: {
      auth: {
        clientId: 'f5a29c9e-c08b-43b5-b655-c17442806941',
        authority:
          'https://ucliveidentityb2ctest.b2clogin.com/ucliveidentityb2ctest.onmicrosoft.com/B2C_1a_signup_signin',
        knownAuthorities: ['ucliveidentityb2ctest.b2clogin.com'],
        redirectUri: 'https://connectuctest.canterbury.ac.nz/b2c',
        postLogoutRedirectUri: 'https://connectuctest.canterbury.ac.nz/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: (logLevel: LogLevel, message: string) => {
            console.log('MSALconfig:', message);
          },
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    },
    loginRequest: {
      scopes: ['openid', 'offline_access'],
    },
  },
  useFakeBackend: {
    user: true,
    application: true,
    applicant: true,
    course: true,
    enrolment: true,
    process: true,
    qualification: true,
    referenceData: true,
    document: true,
    lead: true,
    // turn it off because otherwise we get spammed with an annoying modal all the time
    jadeHydrate: false,
    notification: true,
    userActivity: true,
    agency: true,
  },
  features: {
    unlockStages: false,
    notifications: false,
    profile: true,
    course: false,
    status: false,
    applications: false,
    sendEmail: true,
    accountSettings: true,
    offerLetter: true,
  },
  externalScripts: {
    googleMaps: {
      src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA1U9sfT0g4sPJsvs9EJ8m7TkzQ8yUPr8E&libraries=places',
    },
  },
};
