<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link"
  >Skip to main content</a
>
<uc-agent-header-bar showBackLink="true"></uc-agent-header-bar>
<div class="page-container">
  <uc-stages-sidebar [currentProcess]="process"></uc-stages-sidebar>
  <div id="main-content" class="content" [ngSwitch]="task.code">
    <div class="content-inner">
      <uc-agent-future-apply
        [task]="task"
        #currentTask
        *ngSwitchCase="'future-year-apply'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
      ></uc-agent-future-apply>

      <p class="missing-task" *ngSwitchDefault>
        {{ strings.missing(task.title) }}
      </p>
      <div class="page-actions clearfix">
        <div class="errors">
          <uc-message-banner #errorBanner type="error">{{ errorMessage }}</uc-message-banner>
        </div>
        <div class="primary-links">
          <button [attr.data-cy]="'cancel-task'" class="cancel-link" (click)="cancelTask()">
            {{ strings.cancelTask }}
          </button>
          <uc-button
            testSelector="'update-task'"
            [isLoading]="taskIsUpdating"
            class="next-task"
            (click)="triggerTaskUpdate()"
          >
            {{ actionLabel }}
          </uc-button>
        </div>
      </div>
    </div>
    <uc-footer-minimal></uc-footer-minimal>
  </div>
</div>
<uc-tooltip-container></uc-tooltip-container>
