import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { AgentHeaderBarComponent } from './agent-header-bar/agent-header-bar.component';
import { AtomsModule } from '@shared/components/atoms/atoms.module';

@NgModule({
  declarations: [AgentHeaderBarComponent],
  imports: [AtomsModule, MoleculesModule, RouterModule, CommonModule],
  exports: [AgentHeaderBarComponent],
  providers: [],
})
export class AgentOrganismsModule {}
