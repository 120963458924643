import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import strings from '@constants/strings.constants';
import { DocumentService } from '@shared/services/document/document.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { Category, importantCategories } from '@shared/models/uc-file';
import { IMPORTANT_CATEGORIES } from '@shared/constants/states.constants';

@Component({
  selector: 'uc-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss'],
})
export class DocumentTemplateComponent implements OnInit {
  log: Logger;
  allowedCategories: Category[];

  strings = strings.components.template.documentPage;
  helpText = strings.components.organisms.fileUpload.helpText;

  constructor(private documentService: DocumentService, loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.documentService.getCategories().subscribe((cats) => this.setCategories(cats));
  }

  setCategories(cats) {
    const categoryWhitelist = [
      IMPORTANT_CATEGORIES.OFFER_PLACE_COND,
      IMPORTANT_CATEGORIES.OFFER_PLACE_LETTER,
      IMPORTANT_CATEGORIES.UC_OFFER_PLACE,
      IMPORTANT_CATEGORIES.UC_OFFER_PLACE_COND,
      IMPORTANT_CATEGORIES.APP_UPDATE,
    ];
    this.allowedCategories = cats.filter((cat) => categoryWhitelist.indexOf(cat.code) >= 0);
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
