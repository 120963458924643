import { RouterModule, Routes } from '@angular/router';

import { AccountSettingsComponent } from '@app/components/templates/account-settings/account-settings.component';
import { AgentPreProcessPageComponent } from '@app/components/templates/agent-pre-process-page/agent-pre-process-page.component';
import { DashboardTemplateComponent } from '@app/components/templates/dashboard-template/dashboard-template.component';
import { DocumentTemplateComponent } from '@app/components/templates/document-template/document-template.component';
import { InformationRequiredComponent } from '@app/components/templates/information-required-template/information-required-template.component';
import { LoginTemplateComponent } from '@app/components/templates/login-template/login-template.component';
import { AgentApplicantModuleAccessGuard } from '@app/services/guards/agent-applicant-module-access.guard';
import { ProcessHydrateOverlayComponent } from '@shared/components/atoms/process-hydrate-overlay/process-hydrate-overlay.component';
import { OutageTemplateComponent } from '@shared/components/templates/outage-template/outage-template.component';
import { PageNotFoundTemplateComponent } from '@shared/components/templates/page-not-found-template/page-not-found-template.component';
import { AuthOnloadGuard } from '@shared/services/guards/auth-onload.guard';
import { FeatureGuard } from '@shared/services/guards/feature.guard';
import { OutageGuard } from '@shared/services/guards/outage.guard';
import { StudentOnlyRouteGuard } from '@shared/services/guards/student-only-route.guard';
import { UnimpersonationGuard } from '@shared/services/guards/unimpersonation.guard';
import { UserAuthenticatedGuardService } from '@shared/services/guards/user-authenticated-guard.service';
import { interceptBackButtonForModalsGuard } from '@shared/services/modal/intercept-back-button-for-modals.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginTemplateComponent,
    canActivate: [AuthOnloadGuard],
    data: { title: 'Login | Application to Enrol | UC' },
  },
  {
    path: 'information-required/:year',
    component: InformationRequiredComponent,
    canActivate: [UserAuthenticatedGuardService, StudentOnlyRouteGuard],
    data: { title: 'Information Required | Application to Enrol | UC' },
  },
  {
    path: 'documents',
    component: DocumentTemplateComponent,
    canActivate: [UserAuthenticatedGuardService, StudentOnlyRouteGuard],
    data: { title: 'Document Library | Application to Enrol | UC' },
  },
  {
    path: '',
    component: DashboardTemplateComponent,
    canActivate: [UserAuthenticatedGuardService, UnimpersonationGuard],
    data: { title: 'Dashboard | Application to Enrol | UC' },
  },
  {
    path: 'apply/:process',
    component: AgentPreProcessPageComponent,
    canActivate: [UserAuthenticatedGuardService],
    data: { title: 'Apply | Application to Enrol | UC' },
  },
  {
    path: 'apply',
    loadChildren: () => import('@shared/applicant/applicant.module').then((m) => m.ApplicantModule),
    canActivate: [UserAuthenticatedGuardService, AgentApplicantModuleAccessGuard],
    data: { title: 'Apply | Application to Enrol | UC', lockedRoutes: ['apply'] },
  },
  {
    path: 'hydrate',
    component: ProcessHydrateOverlayComponent,
    data: { title: 'Preparing your account | Application to enrol | UC' },
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [UserAuthenticatedGuardService, FeatureGuard],
    data: { title: 'Account Settings | Application to enrol | UC' },
  },
  {
    path: 'outage',
    component: OutageTemplateComponent,
    data: { title: 'UC Outage | Application to Enrol | UC' },
    canActivate: [OutageGuard],
  },
  {
    path: '**',
    component: PageNotFoundTemplateComponent,
    data: { title: '404 | Application to Enrol | UC' },
  },
];

routes.forEach((route) => {
  route.canDeactivate = route.canDeactivate || [];
  route.canDeactivate.push(interceptBackButtonForModalsGuard);
});

export const appRoutesModule = RouterModule.forRoot(routes, {});
