<div
  #dropdown
  class="section section-dropdown dropdown-container"
>
  <button
    [attr.data-cy]="'action-menu'"
    class="dropdown-trigger"
    [class.disabled]="isDisabled"
    [class.open]="open"
    (click)="toggleDropdown()"
  >
    <uc-icon
      class="dropdown-trigger-icon"
      iconType="chevron-down"
      iconWidth="12"
      iconHeight="12"
    ></uc-icon>
  </button>
  <ul
    class="dropdown-menu"
    [class.open]="open"
  >
    <li
      *ngFor="let action of actions; let index = index"
      class="dropdown-menu-item"
      (click)="callHandler(index)"
    >
      <div
        *ngIf="action.iconType"
        class="icon-container"
      >
        <uc-icon
          [iconType]="action.iconType"
          [color]="getIconColorForAction(action)"
          iconWidth="12"
          iconHeight="12"
        ></uc-icon>
      </div>
      <div class="link-container">
        <a
          *ngIf="action"
          class="dropdown-link"
          [class.disabled]="action.isDisabled"
          [class.hide]="showSpinner[index]"
          href="javascript:void(0)"
          [innerHTML]="action.html"
        ></a>
      </div>
      <uc-loader-blip
        color="$colorBlueRibbon"
        [class.hide]="!showSpinner[index]"
        [showBlips]="showSpinner[index]"
      ></uc-loader-blip>
    </li>
  </ul>
</div>
