<uc-message-banner [showCloseButton]="true" type="info">
  <span [innerHTML]="agentReminderString"></span>
</uc-message-banner>
<uc-form-template [formGroup]="form">
  <uc-form-header [title]="task.title"> </uc-form-header>

  <uc-section>
    <uc-subsection>
      <uc-subsection-question [required]="true">{{ strings.yearQuestion }}</uc-subsection-question>
      <uc-reference-data-selector
        type="application_year"
        formControlName="applicationYear"
      ></uc-reference-data-selector>

      <uc-subsection-question [required]="true">{{ strings.legalNameQuestion }}</uc-subsection-question>
      <uc-full-name
        formControlName="legalName"
        [shouldShowPreferred]="false"
        [markDirty]="markFullNameDirty"
        nameLabel="Legal Name"
        testSelector="legal"
      ></uc-full-name>

      <uc-subsection-question [required]="true">{{ strings.dobQuestion }}</uc-subsection-question>
      <uc-form-input>
        <uc-date-selector
          [baseYear]="dateOfBirthYear"
          [range]="85"
          formControlName="birthDate"
          [required]="true"
          testSelector="student-dob"
        ></uc-date-selector>
      </uc-form-input>

      <uc-subsection-question [required]="true">{{ strings.emailQuestion }}</uc-subsection-question>
      <uc-form-input>
        <uc-string-input testSelector="student-email" formControlName="emailAddress"></uc-string-input>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="strings.processTitle">
      <p>{{ strings.processQuestion }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-form-input>
        <uc-subsection-question [required]="true">{{ strings.processQuestionLabel }}</uc-subsection-question>
        <uc-radio-input-group
          [additionalText]="processNameAdditionalText"
          formControlName="processName"
          [options]="processNameOptions"
          [validateOptions]="true"
          groupName="process-name"
        >
        </uc-radio-input-group>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>
