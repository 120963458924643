import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { internalUrls } from '@shared/constants/internalUrls';
import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class UnimpersonationGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  unimpersonateCurrentStudent(): Observable<boolean> {
    return this.userService.userDetail.pipe(
      switchMap((user) => {
        if (!user) {
          this.router.navigate(internalUrls.login);
          return observableOf(false);
        }
        if (user && user.student) {
          return this.userService.unimpersonate().pipe(map(() => true));
        }
        return observableOf(true);
      }),
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.unimpersonateCurrentStudent();
  }
}
