import { get } from 'lodash-es';
import { DateTime } from 'luxon';

import { LEAD_ACTIONS } from '@shared/constants/actions.constants';
import strings from '@shared/constants/strings.constants';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

import { Page } from './page';

export interface LeadTableData {
  leads: Lead[];
  page: Page;
}

export class LeadActions {
  primary: { action: string };
  secondary: { action: string }[];

  constructor(options: any) {
    Object.assign(this, options);
  }

  static serialize(instance: any): any {
    const clone = { ...instance };
    return snakeifyKeys(clone);
  }

  // Lead actions haven't been defined yet - this is temporary code
  resolvedAction(): Promise<boolean> {
    return new Promise(() => {
      // never resolve
    });
  }
}

export class Lead {
  static DATE_FORMAT = 'dd-MM-yyyy';
  static DATE_TIME_FORMAT = `${Lead.DATE_FORMAT} HH:mm`;

  userId: string;
  firstName: string;
  surname: string;
  emailAddress: string;
  academicYear: ReferenceData;
  birthDate: string;
  dateCreated: string;
  dateSubmitted: string;
  state: ReferenceData;
  agentName: string;
  qualifications: {
    code: string;
    priority: number;
    qualificationOccurrence?: string;
  }[];
  agentAgency: string;
  actions: LeadActions;
  draft: boolean;
  studentId: string;
  studyStart: ReferenceData;
  studyStartOther: string;
  processName: ReferenceData;
  intakeLabel?: string;
  hasAppliedConditions?: boolean;

  intakeStrings = strings.components.template.dashboard.intakeLabel;

  constructor(obj) {
    Object.assign(this, obj);
  }

  get qualificationCodes(): string {
    return this.qualifications.length ? this.qualifications.map((q) => q.code).join('/') : null;
  }

  get displayDateSubmitted() {
    return this.formatDate(this.dateSubmitted);
  }

  get displayDateCreated() {
    return this.formatDate(this.dateCreated);
  }

  get displayBirthDate() {
    return DateTime.fromISO(this.birthDate).toFormat(Lead.DATE_FORMAT);
  }

  get stateLabel() {
    return this.draft && this.state && this.state.code ? 'draft' : this.state.code;
  }

  get hasDocumentsAvailable(): boolean {
    return !!this.actions.secondary.find((a) => a.action === LEAD_ACTIONS.DOCUMENTS_AVAILABLE);
  }

  get hasInfoRequired(): boolean {
    return !!this.hasAppliedConditions;
  }

  get studyStartLabel() {
    return get(this.studyStart, 'code', null) && this.intakeStrings(this.studyStart.code);
  }

  static deserialize(payload: any): Lead[] {
    if (payload === null) {
      return null;
    }

    const data = payload.leads || payload.lead || payload;

    return data.map((lead) => {
      const camelized = camelizeKeys(lead);
      camelized.qualifications = camelized.qualifications.map((qual) => camelizeKeys(qual));
      camelized.state = new ReferenceData(camelized.state);
      camelized.academicYear = new ReferenceData(camelized.academicYear);
      camelized.actions = new LeadActions(camelized.actions);
      camelized.processName = new ReferenceData(camelized.processName);
      return new Lead(camelized);
    });
  }

  static serialize(instance: any): any {
    const clone = { ...instance };

    clone.state = ReferenceData.serialize(clone.state);
    clone.actions = LeadActions.serialize(clone.actions);
    clone.processName = ReferenceData.serialize(clone.processName);

    return { lead: snakeifyKeys(clone) };
  }

  formatDate(dateString: string) {
    return dateString ? DateTime.fromISO(dateString).toFormat(Lead.DATE_FORMAT) : null;
  }
}
