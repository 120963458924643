<div
  class="sidenav-trigger"
  *ngIf="hasSideNav"
>
  <uc-icon
    (click)="hamburgerClick()"
    iconType="hamburger"
    iconWidth="24"
    iconHeight="24"
    iconFill="$colorMonza"
  ></uc-icon>
</div>
<div class="header-bar-left">
  <a
    [routerLink]="internalUrls.dashboard.join('/')"
    class="back-link"
    *ngIf="showBackLink; else agencyDashboardTitle"
  >
    <uc-icon
      iconType="back-arrow"
      iconWidth="22"
      iconHeight="22"
    ></uc-icon>
    <span class="back-link-text">{{ strings.headerBar.backToDashboard }}</span>
  </a>
  <ng-template #agencyDashboardTitle>
    <h1 class="header-bar-title body-font-bold">{{ strings.headerBar.agencyDashboard }}</h1>
  </ng-template>
</div>
<div class="toolbar">
  <div
    *ngIf="impersonated"
    class="toolbar-item impersonated"
  >
    <span class="impersonated__title">{{ strings.headerBar.impersonating }}</span
    >{{ impersonated.displayName }}
  </div>
  <uc-header-nav-dropdown></uc-header-nav-dropdown>
</div>
