<div class="login-wrapper">
  <div class="container-fluid">
    <div class="content-panels row">
      <div class="content-panel col-md-6 col-lg-4 col-xlg-3">
        <div class="uc-banner">
          <a class="banner-logo" [href]="urls.ucHomepage">
            <img class="banner-logo-image" src="assets/img/uc-logo@2x.png" alt="UC Logo" />
          </a>
          <h1 class="myuc-logo accent-font">{{ strings.loginForm.header }}</h1>
        </div>
        <div class="max-width-320">
          <div class="help-text">{{ strings.loginForm.aboutText }}</div>
          <uc-login-form [appName]="appNameEnv" [ldapOnly]="true"></uc-login-form>
          <div class="help-text contact" [innerHTML]="strings.loginForm.helpText"></div>
          <div class="copyright">{{ strings.loginForm.copyright }}</div>
        </div>
      </div>
      <div class="col-md-6 col-lg-8 col-xlg-9 hidden-sm hidden-xs hero-image-container">
        <uc-login-image></uc-login-image>
      </div>
    </div>
  </div>
</div>
